<template>
  <Calendar
    @change="fetchData"
    :exams="exams"
    :holidays="holidays"
    name="schoolClassExams"
    :allowedViews="['term']"
    :loading="loading"
  >
    <ReportButton
      color="success"
      text
      resource="report/exams"
      :parameters="{ type: 'person', id: personId }"
      >XLS</ReportButton
    >
  </Calendar>
</template>
<script>
import Calendar from "@/components/Calendar.vue";
import ReportButton from "@/components/ReportButton.vue";

export default {
  name: "Register",
  components: { Calendar, ReportButton },
  props: ["person"],
  data() {
    return {
      loading: false,
      exams: [],
      holidays: [],
    };
  },
  computed: {
    personId() {
      return this.person ? this.person.id : -1;
    },
  },
  methods: {
    async fetchData(start, end) {
      if (!this.person) return;

      this.loading = true;
      this.holidays = await this.apiList({
        resource: "common/holiday",
        query: `startDate=${start}&endDate=${end}`,
      });
      this.exams = (
        await this.apiList({
          resource: "register/note",
          query: `person=${this.person.id}&type=exam&startDate=${start}&endDate=${end}`,
        })
      ).filter((el) => el.plannedExam);

      this.loading = false;
    },
  },
};
</script>
